import Layout from '../layouts/layout';
import Constants from '../constants';

import * as styles from './index.module.css';
import spacer from '../images/spacer-1.svg';
import spacer2 from '../images/spacer-2.svg';
import spacer3 from '../images/spacer-3.svg';
import spacer4 from '../images/spacer-4.svg';
import me from '../images/me.webp';
import event from '../images/IMG_1075.webp';
import mail from '../images/mail.svg';
import whatsapp from '../images/whatsapp.svg';
import teams from '../images/teams.svg';

const IndexPage = () => {
  return (
    <Layout title="Inicio">
      <section className={styles.imageGrid}>
        <a href="/sanacion" className={`${styles.thumb} ${styles.first}`}>
          <h2>Sanación del Útero</h2>
        </a>
        <a href="/placer" className={`${styles.thumb} ${styles.second}`}>
          <h2>Placer femenino</h2>
        </a>
        <a href="/doula" className={`${styles.thumb} ${styles.third}`}>
          <h2>Doula</h2>
        </a>
      </section>
      <section id="story" className={styles.history}>
        <div className={styles.spacer}>
          <img src={spacer} alt="" />
          <img src={spacer} alt="" />
          <img src={spacer} alt="" />
        </div>
        <h1>Historia</h1>
        <img className={styles.profilePic} src={me} alt="Yo" />
        <ul>
          <li>Soy una observadora de mi propio camino.</li>
          <li>Nieta de una partera del Urabá antioqueño.</li>
          <li>Hija de la vida y el amor.</li>
          <li>Soy una mujer respetuosa de la naturaleza y sus ciclos.</li>
          <li>Mujer enamorada de las plantas medicinales.</li>
          <li>Amante de la luna y sus ciclos.</li>
          <li>De profesión ingeniera de procesos.</li>
          <li>Aprendiz de la energía a través de una maestría en Reiki.</li>
          <li>Madre de un hijo con Síndrome de Down.</li>
          <li>Protectora y aprendiz de mi útero.</li>
          <li>
            Acompañante de la maternidad transformadora (Doula de gestación,
            parto y posparto).
          </li>
          <li>
            Aprendiz de la madre vida, muerte, vida (Doula de duelo perinatal).
          </li>
          <li>Coach transpersonal de la escuela RIIHS.</li>
          <li>Soy Romero &amp; Manzanilla.</li>
        </ul>
      </section>
      <section id="events" className={styles.events}>
        <div className={styles.spacer}>
          <img src={spacer2} alt="" />
          <img src={spacer2} alt="" />
          <img src={spacer2} alt="" />
        </div>
        <h1>Próximos eventos</h1>
        <div className={styles.eventsInner}>
          <div className={styles.evt}>
            <img className={styles.evtImg} src={event} alt="Evento" />
            <div className={styles.evtBody}>
              <div className={styles.evtTime}>
                Vie, 19 Agosto, 07:00 pm (COT)
              </div>
              <div className={styles.evtTitle}>
                Grupo de Apoyo: Duelo por trisomía 21
              </div>
              <div className={styles.evtDesc}>
                Mi hijo tiene Sindrome de Down, ¿y ahora qué?
              </div>
              <div className={styles.evtPlace}>
                <strong>MS Teams</strong> ID: 293 215 450 346 - Código: Xzzzzi
              </div>
              <div className={styles.evtMore}>
                <strong>Aporte voluntario - PayPal</strong>:
                hola@romeroymanzanilla.co
              </div>
            </div>
            {new Date().toISOString().startsWith('2022-08-18') && (
              <a
                className={styles.evtLink}
                href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGI0MDE1MGQtOTFiMC00ZmZjLWI3NmEtY2E4NDk2ODRlZWNi%40thread.v2/0?context=%7b%22Tid%22%3a%22009d90bd-4c94-4b55-9c48-8d4c8ac91f79%22%2c%22Oid%22%3a%22b0360b17-95b0-49e0-857f-615dbddec45b%22%7d"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ingresa aquí
              </a>
            )}
          </div>
        </div>
      </section>
      <section id="contact" className={styles.contact}>
        <div className={styles.spacer}>
          <img src={spacer3} alt="" />
          <img src={spacer3} alt="" />
          <img src={spacer3} alt="" />
        </div>
        <h1>Contacto</h1>
        <div className={styles.contactLinks}>
          <a href="mailto:hola@romeroymanzanilla.co">
            <img src={mail} alt="Correo" />
            hola@romeroymanzanilla.co
          </a>
          <div>
            <img src={spacer4} alt="" />
          </div>
          <a
            href={Constants.links.whatsapp}
            target="blank"
            rel="noopener noreferrer"
          >
            <img src={whatsapp} alt="WhatsApp" />
            +57 301 540 0307
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
